import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import SectionTitle from "@ui/section-title";
import Text from "@ui/text";
import Heading from "@ui/heading";
import Image from "@ui/image";
import Anchor from "@ui/anchor";
import {
  TranslationWrapper,
  TranslationInner,
  TranslationContentbox,
  TranslationContentboxInner,
  TranslationContentboxIconwrap,
  TranslationContentboxTextWrap,
} from "./style";

const TranslationManagement = React.memo(({
  HeadingStyle,
  data,
}) => {
  return (
    <TranslationWrapper id={data.section}>
      <TranslationInner>
        <Container>
          <Row>
            <Col>
              <SectionTitle
                title={data.section_title?.title}
                subtitle={data.section_title?.subtitle}
           
              />
            </Col>
          
          </Row>
          {data.section_title.description}
          <Row>
            <Col
              lg={12}
              className="text-center mt-5 d-flex align-items-center justify-content-center"
            >
              <Image
                src={data.images[0].src}
                alt={data.images[0]?.alt || "Icon"}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TranslationContentbox>
                {(data && !!data.items) &&
                  data.items.map((el, index) => (
                      <TranslationContentboxInner key={index}>
                        <TranslationContentboxIconwrap>
                          <Image
                            src={el.images[0].src}
                            alt={el.images[0]?.alt || "Icon"}
                          />
                        </TranslationContentboxIconwrap>
                        <TranslationContentboxTextWrap>
                          <Heading {...HeadingStyle}>{el.title}</Heading>
                          <Text>{el.description}</Text>
                        </TranslationContentboxTextWrap>
                        {( !! el.path?
                          <Anchor key={index} path={el.path} target="_blank">Learn more &rsaquo;</Anchor> : ""
                        )}
                      </TranslationContentboxInner>
                  ))}
              </TranslationContentbox>
            </Col>
          </Row>
        </Container>
      </TranslationInner>
    </TranslationWrapper>
  );
})

TranslationManagement.propTypes = {
  HeadingStyle: PropTypes.object,
};
TranslationManagement.defaultProps = {
  HeadingStyle: {
    as: "h6",
    color: "#fff",
    fontsize: "22px",
    fontweight: "bold",
  },
  buttonStyle: {
    to: "/translation-management",
    mt: "40px",
    responsive: {
      xlarge: {
        mt: "10px",
      },
    },
  },
};
export default TranslationManagement;