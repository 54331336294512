import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Text from "@ui/text";
import SectionTitle from "@ui/section-title";
import Image from "@ui/image";

import {
    LeadingWayWrapper,
    LeadingWayInner,
    LeadingWayHeading,
    LeadingWayList,
    LeadingWayItem
} from "./style";

const LeadingWay = React.memo(({ data }) => {
    return (
        <LeadingWayWrapper id={data.section}>
            <LeadingWayInner>
                <Container>
                    <Row>
                        <Col>
                            <LeadingWayHeading>
                                {data &&
                                    data.images.map((el, index) => (
                                        <Image
                                            key={index}
                                            src={el.src}
                                            alt={el.alt || "Icon"}
                                        />
                                    ))}
                                <SectionTitle
                                    title={data.section_title?.title}
                                />
                            </LeadingWayHeading>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <LeadingWayList>
                                {data &&
                                    data.items.map((el, index) => (
                                        <LeadingWayItem key={index}>
                                            <Text as="span">#{el.id}</Text>
                                            <Text dangerouslySetInnerHTML={{ __html: el.title }}></Text>
                                        </LeadingWayItem>
                                    ))}
                            </LeadingWayList>
                        </Col>
                    </Row>
                </Container>
            </LeadingWayInner>
        </LeadingWayWrapper>
    );
})

export default LeadingWay;
