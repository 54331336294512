import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import SectionTitle from "@ui/section-title";
import Text from "@ui/text";
import Button from "@ui/button";
import { Link } from "gatsby";
import Image from "@ui/image";

import {
  ReachoverWrapper,
  ReachoverInner,
  ReachoverRows,
  ButtonWrap,
} from "./style";

const ReachOver = React.memo(({ buttonStyle, data }) => {
  return (
    <ReachoverWrapper id={data.section}>
      <ReachoverInner>
        <Container>
          <ReachoverRows>
            {data &&
              data.items.map((el, index) => (
                <Row key={index}>
                  <Col lg={7}>
                    <SectionTitle
                      title={el.title}
                      subtitle={el.id}
                    />
                    <Text className="mt-5">{el.description}</Text>
                    <ButtonWrap>
                      <Button as={Link} to={el.path} {...buttonStyle}>
                      {el.subject}
                      </Button>
                    </ButtonWrap>
                  </Col>
                  <Col lg={5}>
                    <Image
                      src={el.images[0].src}
                      alt={el.images[0]?.alt || "Icon"}
                    />
                  </Col>
                </Row>
              ))}
          </ReachoverRows>
        </Container>
      </ReachoverInner>
    </ReachoverWrapper>
  );
})

ReachOver.propTypes = {
  SectionTitleStyle: PropTypes.object,
};
ReachOver.defaultProps = {
  SectionTitleStyle: {
  },
  buttonStyle: {
    skin: "light",
    mt: "40px",
    responsive: {
      xlarge: {
        mt: "10px",
      },
    },
  },
};
export default ReachOver;