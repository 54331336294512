import styled, { themeGet } from "@styled";

export const LeadingWayWrapper = styled.section`
    padding: 50px 0 40px;
    @media (max-width: 767px) {
        padding: 20px 0;
    }
`;
export const LeadingWayInner = styled.div``;
export const LeadingWayHeading = styled.div`
    display: flex;
    align-items: center;
    grid-gap: 20px;
    margin-bottom: 40px;
    .section-title {
        margin: 0;
        h2 {
            padding: 0;
            border: none;
        }
    }
`;
export const LeadingWayList = styled.div`
    display: flex;
    align-items: center;
    grid-gap: 10px;
    @media (max-width: 991px) {
        flex-direction: column;
        align-items: flex-start;
        grid-gap: 20px;
    }
`;
export const LeadingWayItem = styled.div`
    flex: 1 1 100%;
    display: flex;
    align-items: center;
    grid-gap: 16px;
    span {
        --size: 76px;
        width: var(--size);
        min-width: var(--size);
        height: var(--size);
        min-height: var(--size);
        border-radius: 50px;
        background-color: ${themeGet("colors.primary")};
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 33px;
        font-weight: bold;
        @media (max-width: 1599px) {
            font-size: 26px;
            --size: 70px;
        }
        @media (max-width: 1199px) {
            font-size: 20px;
            --size: 60px;
        }
    }
    p {
        line-height: 1.4;
        @media (max-width: 1199px) {
            font-size: 16px;
        }
    }
`;
