import styled, { themeGet, device } from "@styled";

export const KeybenifitsWrapper = styled.section`
  padding: 90px 0;
  background: linear-gradient(
    320deg,
    ${themeGet("colors.primaryDark")},
    ${themeGet("colors.primary")}
  );
  .section-title {
    h2 {
      border-color: #fff;
    }
  }
`;
export const KeybenifitsInner = styled.div``;
export const KeybenifitsList = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
  ${device.medium} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${device.xlarge} {
    grid-template-columns: repeat(3, 1fr);
  }
`;
export const KeybenifitsItem = styled.div`
  min-height: 230px;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid rgb(255 255 255 / 55%);
  perspective: 1000px;
  transition: ${(props) => props.theme.transition};
  &:hover {
    transform: rotateY(180deg);
    .flipFront {
      opacity: 0;
      visibility: hidden;
    }
  }
`;
export const KeybenifitsItemFront = styled.div`
  min-height: 230px;
  background: #ff8965;
  padding: 30px 25px;
  display: flex;
  align-items: center;
  transition: ${(props) => props.theme.transition};
  backface-visibility: hidden;
  position: relative;
  height: 100%;
  z-index: 2;
`;
export const KeybenifitsItemBack = styled.div`
  transition: ${(props) => props.theme.transition};
  transform: rotateY(180deg);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px 25px;
  height: 100%;
  transform: rotateY(180deg);
  transition: ${(props) => props.theme.transition};
  p {
    font-size: 15px;
    @media ${device.large} {
      font-size: 14px;
    }
  }
  background: rgb(225 225 225 / 90%);
  a {
    color: #fa4f19;
    font-size: 16px;
    font-weight: bold;
    @media ${device.large} {
      font-size: 14px;
    }
  }
`;
export const KeybenifitsItemIconwrap = styled.div`
  min-width: 88px;
  width: 88px;
  height: 88px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  margin-right: 20px;
`;
export const KeybenifitsItemTextwrap = styled.div`
  h6,
  h2 {
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    margin: 0;
    @media ${device.large} {
      font-size: 18px;
    }
  }
`;
