import styled, { themeGet } from "@styled";

export const MachineTranslationWrapper = styled.section`
  padding: 90px 0;
  background: linear-gradient(
    320deg,
    ${themeGet("colors.secondaryDark")},
    ${themeGet("colors.secondary")}
  );
  .section-title {
    p {
      color: #fff;
    }
  }
`;
export const MachineTranslationInner = styled.div``;
export const MachineTranslationBlock = styled.div`
  border: 1px solid #917ba8;
  border-radius: 4px;
  background: rgb(255 255 255 / 11%);
  margin: 15px 0;
`;
export const MachineTranslationBlockInner = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0;
  min-height: 190px;
  transition: ${themeGet("transition")};
  &:hover {
    box-shadow: 0 0 40px -16px rgb(255 255 255);
    transform: translateY(-3px);
  }
  .mac-trs-iconwrap {
    min-width: 140px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mac-trs-textwrap {
    border-left: 1px solid #917ba8;
    padding: 0 24px;
    h6 {
      color: #fff;
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    p {
      color: #ccb8fe;
      font-size: 15px;
    }
  }
`;
export const ButtonWrap = styled.div`
  margin-top: 34px;
`;
