import styled, { themeGet } from "@styled";

export const ReachoverWrapper = styled.section`
  padding: 85px 0;
`;
export const ReachoverInner = styled.div``;
export const ReachoverRows = styled.div`
  .gatsby-image-wrapper {
    max-width: 100%;
  }
  .row {
    &:nth-child(2n) {
      flex-direction: row-reverse;
      margin-top: 40px;
    }
  }
`;
export const ButtonWrap = styled.div`
  margin-top: 34px;
  margin-bottom: 20px;
  a {
    background: ${themeGet("colors.primary")};
    color: #fff !important;
    &:hover {
      text-decoration: none;
      background: ${themeGet("colors.secondaryDark")};
    }
  }
`;
