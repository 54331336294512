import styled, { themeGet, device } from "@styled";
import blueWaveBg from "@data/images/bg/blue-wave-bg.webp";

export const TranslationWrapper = styled.section`
  padding: 166px 0;
  background-image: url(${blueWaveBg});
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const TranslationInner = styled.div`
  .gatsby-image-wrapper {
    max-width: 100%;
  }
`;
export const TranslationContentbox = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 20px;
  margin-top: 80px;
  ${device.medium} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${device.xlarge} {
    grid-template-columns: repeat(4, 1fr);
  }
  a {
    display: inherit;
  }
`;
export const TranslationContentboxInner = styled.div`
  padding: 40px 24px;
  border: 1px solid #917ba8;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(255 255 255 / 11%);
  transition: ${themeGet("transition")};
  ${device.large} {
    padding: 30px 14px;
  }
  &:hover {
    box-shadow: 0 0 40px -16px rgb(255 255 255);
    transform: translateY(-3px);
  }

  p {
    margin-bottom: 20px;
  }

  a {
    margin-top: auto;
    color: #f94f19;
    font-weight: bold;

    &:hover {
      color: #fff;
    }
  }
`;
export const TranslationContentboxIconwrap = styled.div``;
export const TranslationContentboxTextWrap = styled.div`
  h6 {
    margin: 20px 0;
    font-size: 22px;
    font-weight: bold;
    ${device.large} {
      font-size: 18px;
    }
  }
  p {
    color: #fff;
    ${device.large} {
      font-size: 14px;
    }
  }
`;
export const ButtonWrap = styled.div`
  margin-top: 34px;
`;
